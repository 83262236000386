<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "MyAccount",

  metaInfo: { title: "My Calendar" },

  extends: View,

  mixins: [LoadSections(["resource/sections/resource-calendar"])],

  props: {
    id: {
      type: String,
      default: "resourcecalendar",
    },
  },
};
</script>
